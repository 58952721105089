import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import ProjectTile from "../page-elements/ProjectTile";

function Projects() {
    return (
        <div id="pageProjects" className="page grid grid-cols-2 grid-flow-row-dense md:mr-60 gap-8">
            <ProjectTile icon={faArrowUpRightFromSquare} title="Tabletop-Simulator" description="A simple website where you can play any card game online with your friends." link="https://tabletop-simulator.netlify.app/register" linktext="Visit the website here! (for desktop users only)" />
            <ProjectTile icon={faArrowUpRightFromSquare} title="UCLA SMV" description="A dynamic website showcasing the UCLA supermileage club." link="https://bruinsmv.netlify.app/" linktext="Visit the website here!" />
        </div>
    );
  }
  
  export default Projects;
  